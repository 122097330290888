body {
  font-family: 'Cinzel', serif;
  margin: 0;
  font-optical-sizing: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:lang(th) {
  font-family: 'Noto Sans Thai', sans-serif;
}

@media (max-width: 768px) {
  html {
    zoom: 1;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
