
  
body{
    background-color: rgb(255, 255, 255);
    position: relative;
    width: 100%;
    
}
Link{
    font-weight: bold;
    color: #242424 !important;
}

.headline{
    font-family: 'Cinzel';
    font-style: italic;
}