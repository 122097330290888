.home-page {
  margin: 0;
  padding: 0;
  overflow: hidden;
  box-sizing: border-box;
}

/* Full-screen section styling */
.full-screen-section {
  position: relative;
  width: 1920px;
  height: 1080px;
  max-width: 100%;
  max-height: 100vh;
  /* Full viewport height */
  display: flex;
  justify-content: center;
  overflow: hidden;
  box-sizing: border-box;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.content-overlay {
  position: absolute;
  top: 30%;
  /* Positioned above the bottom of the screen */
  text-align: center;
  z-index: 2;
  color: white;
  animation: fadeIn 2s ease-in-out;
  /* Fade-in animation */
}

.collection-name {
  position: relative;
  font-size: 2.5rem;
  color: rgb(245, 245, 245);
  font-weight: bold;
  bottom: 0;
  top: 75%;
  animation: slideInFromBottom 1s ease-in-out forwards;
  z-index: 100;
}

.see-collection-btn {
  position: relative;
  bottom: 0;
  top: 85%;
  /* Start at 50px from the bottom of the section */
  left: 50%;
  transform: translateX(-50%);
  padding: 15px 30px;
  font-size: 1.2em;
  background-color: rgba(0, 0, 0, 0.922);
  color: #f8f8f8;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  z-index: 3;
}

.see-collection-btn.sticky {
  position: fixed;
  /* Fix to the screen when scrolling */
  bottom: 50px;
  /* Keep 50px from the bottom of the viewport */
  left: 50%;
  transform: translateX(-50%);
}

.see-collection-btn:hover {
  background-color: rgba(255, 255, 255, 0.7);
  color: black;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slideInFromBottom {
  from {
    opacity: 0;
    transform: translateY(50px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.section2-container h2,
h3 {
  text-align: center;
}

.slider-container {
  position: relative;
  overflow: hidden;
  width: 90vw;
  margin: 20px auto;
  padding: 0 20px;
}

.slider {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.slider.active {
  cursor: grabbing;
}

.slider-content {
  display: flex;
  gap: calc(150px + 0.5vw);
}

.slider::-webkit-scrollbar {
  display: none;
  /* Hide the scrollbar */
}

.slider {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.section-news {
  margin-bottom: 1%;
  padding-top: 3%;
}

@media (max-width: 1200px) {
  .slider-container {
    width: 90vw;
    /* Adjust width for smaller screens */
  }

  .slider-card {
    flex: 1 1 calc(33.33% - 20px);
    /* Adjust card width for smaller screens */
  }
}

@media only screen and (max-width: 768px) {
  .full-screen-section{
    max-height: 80vh;
  }
  .slider-container {
    width: 100vw;
    /* Adjust width for smaller screens */
  }

  .slider-card {
    flex: 1 1 calc(50% - 20px);
    /* Adjust card width for smaller screens */
  }

  .collection-name {
    font-size: 2rem;
    top: 80%;
    bottom: 0;
  }

  .see-collection-btn {
    padding: 12px 24px;
    top: 80%;
    bottom: 0;
  }

  .background-image {
    object-fit: cover;
    cursor: pointer;
  }

  .content-overlay {
    top: 25%;
  }

  .section2-container h2 {
    font-size: 1.5rem;
  }

  .section2-container h3 {
    font-size: 1.2rem;
  }

  .slider-container {
    width: calc(200px * 2 + 60px * 1);
    /* Show 2 cards on small screens */
    scroll-behavior: smooth;
  }

}

@media only screen and (max-width: 480px) {
  .collection-name {
    font-size: 2rem;
    top: 80%;
    bottom: 0;
  }

  .see-collection-btn {
    padding: 12px 24px;
    top: 80%;
    bottom: 0;
  }

  .background-image {
    object-fit: cover;
  }

  .content-overlay {
    top: 20%;
  }

  .section2-container h2 {
    font-size: 1.2rem;
  }

  .section2-container h3 {
    font-size: 1rem;
  }

  .slider-container {
    width: 100vw;
    /* Adjust width for smaller screens */
  }

  .slider-card {
    flex: 1 1 100%;
    /* Adjust card width for smaller screens */
  }
}

.split-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px;
  background-color: #f8f8f8;
  cursor: pointer;
}

.split-left,
.split-right {
  opacity: 0;
  transform: translateY(20px);
  /* Start below the normal position */
  transition: opacity 1s ease, transform 1s ease;
}

.split-section.visible .split-left,
.split-section.visible .split-right {
  opacity: 1;
  transform: translateY(0);
  /* Return to normal position */
}

.split-left {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.tilted-image-container {
  width: 70%;
  overflow: hidden;
  transform: rotate(-10deg);
  border-radius: 2px;
}

.tilted-image {
  width: 100%;
  height: auto;
  transform: rotate(10deg);
  transition: transform .2s;
  /* Animation */
}

.tilted-image:hover {
  transform: scale(1.5);
}

.split-right {
  flex: 1;
  padding: 20px;
  text-align: left;
}

@media (max-width: 768px) {
  .slider-content {
    gap: calc(10px + 0.5vw);
  }
  .split-section {
    flex-direction: column;
    /* Stack the sections vertically */
    text-align: center;
    padding: 20px;
  }

  .split-left,
  .split-right {
    flex: none;
    width: 100%;
  }

  .tilted-image-container {
    transform: none;
    /* Remove the tilt on smaller screens */
    width: 100%;
  }

  .split-right {
    transform: none;
    /* Remove the sliding effect on smaller screens */
    padding: 10px 0;
    opacity: 1;
    /* Make text visible without delay */
  }

  .split-right.text-visible {
    opacity: 1;
    transform: none;
  }

  .split-right h2 {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }

  .split-right p {
    font-size: 1rem;
    margin-bottom: 20px;
  }
}

@media (max-width: 480px) {
  .split-right h2 {
    font-size: 1.2rem;
  }

  .split-right p {
    font-size: 0.9rem;
  }
}