  .product-management {
    max-width: 1100px;
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .header h2 {
    margin: 0;
    color: #333;
  }
  
  .header-actions {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .search-bar {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 500px;
  }
  
  .product-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .product-table th, .product-table td {
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .product-table th {
    background-color: #e6e6e6;
  }
  
  .product-table tbody tr:nth-child(odd) {
    background-color: #f2f2f2;
  }
  
  .product-table tbody tr:nth-child(even) {
    background-color: #e0e0e0;
  }
  
  .product-table td {
    background-color: #ffffff;
  }
  
  @keyframes slideDown {
    0% {
      transform: translateY(-20px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  @keyframes slideUp {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(-20px);
      opacity: 0;
    }
  }
  
  .variation-row {
    background-color: #333333;
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    transition: max-height 0.3s ease, opacity 0.3s ease;
    display: none;
  }
  
  .variation-row.open {
    max-height: 500px; /* Adjust based on your content */
    opacity: 1;
    display: table-row; /* Show the row when expanded */
    animation: slideDown 0.3s ease forwards;
  }
  
  .variation-row.closing {
    animation: slideUp 0.3s ease forwards;
  }
  
  .product-table tbody tr + tr {
    margin-top: 0;
  }
  
  .variation-table tbody tr {
    animation: slideDown 0.3s ease;
  }

  .variation-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  .variation-table th, .variation-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #666;
    color: #fff;
  }
  
  .variation-table th {
    background-color: #444444;
  }
  
  .variation-table td {
    background-color: #555555;
  }
  
  .variation-image-wrapper {
    position: relative;
  }
  
  .variation-image {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    transition: transform 0.3s ease;
  }
  
  .variation-image-wrapper:hover .variation-image {
    transform: scale(1.5);
  }
  
  .addmorebutton {
    padding: 12.5px 30px;
    border: 0;
    border-radius: 100px;
    background-color: #2ba8fb;
    color: #ffffff;
    font-weight: Bold;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
  }
  
  .addmorebutton:hover {
    background-color: #6fc5ff;
    box-shadow: 0 0 20px #6fc5ff50;
    transform: scale(1.1);
  }
  
  .addmorebutton:active {
    background-color: #3d94cf;
    transition: all 0.25s;
    -webkit-transition: all 0.25s;
    box-shadow: none;
    transform: scale(0.98);
  }

  .delbutton {
    padding: 12.5px 30px;
    border: 0;
    border-radius: 100px;
    background-color: #e80c0c;
    color: #ffffff;
    font-weight: Bold;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
  }
  
  .delbutton:hover {
    background-color: #ff0f0f;
    box-shadow: 0 0 20px #6fc5ff50;
    transform: scale(1.1);
  }
  
  .delbutton:active {
    background-color: #bf0909;
    transition: all 0.25s;
    -webkit-transition: all 0.25s;
    box-shadow: none;
    transform: scale(0.98);
  }

  .product-table td,
.product-table th {
  /* Other existing styles */
  white-space: nowrap; /* Prevents the text from wrapping to the next line */
  overflow: hidden; /* Hides the overflowing text */
  text-overflow: ellipsis; /* Adds the ... for overflowing text */
}

.product-table td:nth-child(2) {
  max-width: 150px; /* Adjust this to your preferred width */
  white-space: normal; /* Allows wrapping within the specified max height */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limit to 3 lines */
  -webkit-box-orient: vertical;
  line-height: 1.2; /* Adjust line height for readability */
  max-height: calc(1.2em * 3); /* Matches the line height * number of lines */
}