.card {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin: 10px auto;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    border-radius: 3px;
    border: 1px solid #f1f1f1;
    width: 81%;
    overflow: hidden;
    box-shadow: 12px 12px 1px rgba(0, 0, 0, 0.1);
    background-color: white;
}

.card.visible {
    opacity: 1;
}

.card-content {
    display: flex;
    flex-direction: row;
}

.text-container {
    flex: 1;

}

.text-container h2 {
    margin: 0;
    padding: 0;
    font-size: 3rem;
}

.text-container p {
    margin: 0;
    padding-top: 10%;
    font-size: 1.5rem;
}

.video-container {
    flex: 1;
    display: flex;
    justify-content: left;
    align-items: left;
}

.video-container video {
    border-radius: 1px;
    margin-left: 15%;
}

.link-tocollection{
    margin: auto;
    width: 50%;
    margin-top: 10%;
}

/* Responsive styles */
@media (max-width: 768px) {
    .card-content {
        max-width: 100%;
        flex-direction: column;
        margin: 0;
    }

    .video-container {
        justify-content: center;
        align-items: center;
    }
    .video-container video {
        width: 100%;
        height: auto;
    margin-left: 0;

    }

    .text-container {
        padding: 20px;
        text-align: center;
    }

    .text-container h2 {
        font-size: 2rem;
    }

    .text-container p {
        font-size: 1.5rem;
        padding-top: 10px;
    }
}