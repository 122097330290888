.slideshow {
    position: relative;
    width: 100%;
    height: 100vh; /* Full viewport height */
    overflow: hidden;
  }
  
  .slide {
    position: absolute;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.5s ease-in-out;
  }
  
  .slide.active {
    left: 0;
    opacity: 1;
  }
  
  .slide-image, .slide-video {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the image or video covers the entire slide */
    object-position: center; /* Center the content within the slide */
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .slide-content {
    position: absolute;
    bottom: 20%;
    left: 10%;
    color: white;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
    max-width: 50%;
  }
  
  .slideshow-dots {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 10px;
  }
  
  .dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.7);
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .dot.active {
    background-color: white;
  }
  