.about-us-page {
    color: #333;
    padding: 110px 20px;
    max-width: 82%;
    margin: 0 auto;
}

.company-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
}

.company-text {
    flex: 1;
    padding-right: 20px;
}

.company-text h2 {
    font-size: 8rem;
    margin-bottom: 20px;
}

.company-text p {
    font-size: 1.2rem;
    line-height: 1.6;
}

.company-image {
    flex: 1;
    text-align: center;
}

.company-image img {
    width: 100%;
    max-width: 500px;
    border-radius: 2px;
}

.company-video {
    text-align: center;
    margin-bottom: 50px;
}

.company-video h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.video-container {
    max-width: 800px;
    margin: 0 auto;
}

.about-video {
    width: 100%;
    border-radius: 10px;
}

.contact-us {
    text-align: center;
    margin-bottom: 50px;
}

.contact-us h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.contact-form {
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.contact-form input, .contact-form textarea {
    width: 100%;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
}

.contact-form button {
    padding: 15px;
    border: none;
    background-color: #333;
    color: white;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.contact-form button:hover {
    background-color: #555;
}

/* Responsive Styling */
@media (max-width: 768px) {
    .company-info {
        flex-direction: column;
        text-align: center;
    }

    .company-text {
        padding-right: 0;
        margin-bottom: 20px;
    }

    .company-image img {
        max-width: 100%;
    }
}

.beyou-text{
    margin: auto;
    width: 50%;
    font-weight: bold;
    font-size: 1.3em !important;
}