
._social-links:hover {
	box-shadow: -8px 0px 20px #595959;
	padding: 1em;
}

._links-list {
	list-style: none;
	display: flex;
	flex-direction: column;
	gap: 1em;
}

._social-link a {
	color: #111;
	font-size: 1.5rem;
	transition: all 200ms ease;
}

._social-link a:visited {
	color: #111;
}

._social-link a:hover {
	color: hotpink;
	font-size: 1.75rem;
}

.sticky {
	z-index: 99;
}