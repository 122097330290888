.slideselect {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding-top: 5%;
}

.selectcard-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    /* Space between cards */

}

.select-card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    width: 40%;
}

.selectcard {
    position: relative;
    width: 400px;
    height: 550px;
    text-decoration: none;
    color: white;
    /* Text color */
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
}

.selectcard:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.selectcard:hover::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    transition: background 0.3s;
}

.selectcard:hover .selectcard-image {
    filter: brightness(80%);
}

.selectcard-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.selectcard-text {
    font-size: 2rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    font-style: italic;
    position: absolute;
    bottom: 0;
    width: 60%;
}

@media screen and (max-width: 768px) {
    .selectcard {
        width: 40%;
        height: 40%;
    }
    .selectcard-text {
        font-size: 1rem;
    }
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #9d9d9d;
    padding: 10px 0;
}

.header-title {
    margin: 0;
    font-size: 1.5em;
}

.header-button {
    border: 1px solid #000;
    color: #000;
    background: none;
    padding: 20px 20px;
    cursor: pointer;
    background-color: #00000000;
    transition: all 0.3s;
}
.header-button:hover {
    background-color: #272727;
    color: white;
}