.collection-form-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.collection-form-container h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
}

.collection-form-container label {
    display: block;
    font-weight: bold;
    margin-bottom: 8px;
    color: #555;
}

.collection-form-container select,
.collection-form-container input[type="text"],
.collection-form-container textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.collection-form-container select {
    cursor: pointer;
}

.collection-form-container textarea {
    resize: vertical;
    min-height: 100px;
}

.collection-form-container button {
    width: 100%;
    padding: 12px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.collection-form-container button:hover {
    background-color: #218838;
}

.collection-form-container .form-group {
    margin-bottom: 20px;
}

.collection-form-container select option {
    padding: 10px;
    color: #333;
    font-size: 16px;
}

.collection-form-container .form-group:last-child {
    margin-bottom: 0;
}
