.create-product-form {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    min-height: 60vh;
    /* Center the content vertically */
}

.advice-text {
    margin-bottom: 20px;
    font-size: 1.2em;
    color: #666;
}

.category-buttons {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    /* Automatically adjust the number of columns */
    gap: 20px;
    max-width: 600px;
    width: 100%;
}

.category-button {
    background-color: #333;
    color: white;
    padding: 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    width: 100%;
}

.category-button:hover {
    background-color: #555;
}

.category-icon {
    font-size: 2em;
    margin-bottom: 10px;
}

.product-form {
    margin-top: 20px;
    width: 100%;
    max-width: 600px;
    text-align: left;
    /* Align text to the left in the form */
}

.product-form label {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.product-form input,
.product-form select,
.product-form textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-family: 'Arial', sans-serif;
    width: 100%;
}

.product-form textarea {
    height: 100px;
    /* Fixed height */
    max-height: 100px;
    overflow-y: auto;
    /* Adds vertical scroll if content exceeds height */
    resize: none;
    /* Optional: Prevents manual resizing of the textarea */
}

.product-form button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #333;
    color: white;
    font-family: 'Arial', sans-serif;
    cursor: pointer;
    transition: background-color 0.3s;
}

.product-form button:hover {
    background-color: #555;
}

.product-form-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.product-form-header h2 {
    margin: 0;
    font-size: 1.5em;
}

.back-button {
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    background-color: #333;
    color: white;
    font-family: 'Arial', sans-serif;
    cursor: pointer;
    transition: background-color 0.3s;
    text-align: right;
    white-space: nowrap;
    /* Prevent the button from scaling with the text */
}

.back-button:hover {
    background-color: #555;
}
.new-style-input {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .new-style-input input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .new-style-input button {
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    background-color: #333;
    color: white;
    cursor: pointer;
    font-size: 1.2em;
    transition: background-color 0.3s;
  }
  
  .new-style-input button:hover {
    background-color: #555;
  }

  .loading-spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000; /* Ensure it is above everything else */
}

.spinner {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
