.random-product-section {
  background-color: #ffffff;
  width: 100vw;
  margin: 0;
  padding: 50px 0;
  display: flex;
  justify-content: center;
}

.random-product-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.shuffle-button {
  background-color: #114084;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  margin-bottom: 20px;
}

.shuffle-button:hover {
  background-color: #3466aa;
}

.product-card-container {
  margin-left: -5%;
  transition: transform 0.3s ease;
}

.product-card-container img {
  width: 100%;
  /* Image takes the full width of the card */
  height: 100%;
  object-fit: cover;
}

.product-card-container.shuffling {
  animation: shuffle-fast 3s infinite;
}

@keyframes shuffle-fast {
  0% {
    transform: translateY(0);
  }

  25% {
    transform: translateY(-10px);
  }

  50% {
    transform: translateY(10px);
  }

  75% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}