/* zoomableImage.css */

.zoomable-image-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .zoomable-image-container {
    position: relative;
    width: 80%;
    max-width: 1200px;
    background: white;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: rgb(9, 9, 9);
    font-size: 1.5rem;
    cursor: pointer;
    z-index: 10;
  }
  
  .zoom-control {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
  }
  
  .zoom-control button {
    background: none;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
    margin-bottom: 5px;
  }
  
  .image-area {
    position: relative;
    width: 100%;
    height: 80vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
  }
  
  .zoomable-image {
    max-width: 100%;
    max-height: 100%;
    cursor: grab;
    transition: transform 0.3s ease;
  }
  
  .thumbnail-bar {
    display: flex;
    overflow-x: auto;
    padding: 10px;
    background: #f9f9f9;
  }
  
  .thumbnail-image {
    width: 100px;
    height: 100%;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 5px;
    transition: transform 0.3s ease;
  }
  
  .thumbnail-image:hover {
    transform: scale(1.1);
  }
  
/* zoomableImage.css */

/* Other styles... */

.zoom-slider-container {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 20;
    width: 50px;
    height: 300px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .zoom-slider {
    width: 300px; /* Height of the slider becomes the width in horizontal mode */
    height: 10px; /* Width of the slider becomes the height in horizontal mode */
    -webkit-appearance: none;
    appearance: none;
    background: #ccc;
    border-radius: 5px;
    outline: none;
    opacity: 0.7;
    transition: opacity 0.2s;
    transform: rotate(-90deg); /* Rotate slider to make it vertical */
    transform-origin: 50% 50%;
  }
  
  .zoom-slider:hover {
    opacity: 1;
  }
  
  .zoom-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #333;
    cursor: pointer;
  }
  
  .zoom-slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #333;
    cursor: pointer;
  }
  
  .zoomable-image-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    cursor: zoom-in;
  }
  .zoom-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .zoom-container {
    position: relative;
    max-width: 90%;
    max-height: 90%;
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  }
  
  .zoomed-image {
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: 0 auto;
  }