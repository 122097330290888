.collection-list-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
}

h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
}

.create-button {
    background-color: #28a745;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 20px;
    display: block;
    margin-left: auto;
}

.create-button:hover {
    background-color: #218838;
}

.collection-table {
    width: 100%;
    border-collapse: collapse;
}

.collection-table th,
.collection-table td {
    padding: 12px 15px;
    border: 1px solid #ddd;
    text-align: center;
}

.collection-table th {
    background-color: #f4f4f4;
    color: #333;
    font-weight: bold;
}

.collection-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

.collection-table tbody tr:hover {
    background-color: #f1f1f1;
}

.collection-img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 5px;
}

.delete-button {
    background-color: #dc3545;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.edit-button{
    background-color: #6347ed;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.delete-button:hover {
    background-color: #c82333;
}

input[type="checkbox"] {
    transform: scale(1.2);
    cursor: pointer;
}
