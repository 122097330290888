html,
body {
  width: 100%;
  overflow-x: hidden;
  /* Prevent any horizontal scrolling */
}

.product-detail {
  width: 100%;
  /* Ensure it takes up 100% of the available width */
  max-width: 100vw;
  /* Prevent the container from exceeding the viewport width */
  overflow-x: hidden;
  /* Prevent horizontal overflow */
  margin: 0 auto;
  /* Center the content */
}

.slideshow-container {
  width: 100%;
  max-width: 100vw;
  /* Prevent overflow */
  overflow-x: hidden;
  /* Prevent scrolling horizontally */
}

.slideshow {
  overflow: hidden;
  display: flex;
  width: 100%;
  padding-top: 5%;
}

.video-slide {
  width: calc(100%);
  /* Video spans the width of three images */
}

.images-wrapper {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.product-slide {
  flex-shrink: 0;
  width: calc(100% / 3);
  height: 100%;
  object-fit: cover;
}


.video-slide {
  flex-shrink: 0;
  height: 70%;
  width: 100%;
}

.scroll-button {
  background: none;
  border: none;
  font-size: 3rem;
  cursor: pointer;
  position: absolute;
  top: 30%;
  transform: translateY(-50%);
  z-index: 1;
}

.scroll-button.left {
  left: 10px;
}

.scroll-button.right {
  right: 10px;
}


.product-info {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin-left: 10%;
  margin-bottom: 20%;
  transition: all 0.3s ease;
  width: 100%;
}

.product-info.scrolled {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-left: 0;
  /* Reset the margin when scrolled */
  margin-bottom: 0;
  /* Reset the margin when scrolled */
  background-color: rgba(255, 255, 255, 0.9);
  padding: 10px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}


.info-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

/*info cards*/
.product-info {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  transition: all 0.3s ease;
}

.product-info.scrolled {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  max-width: 600px;
  background-color: rgba(0, 0, 0, 0.155);
  padding: 1px;
  border-radius: 2px;
  z-index: 500;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: background-color 0.3s ease, transform 0.3s ease;
}


.product-info.scrolled:hover {
  background-color: rgba(0, 0, 0, 0.307);
}

.info-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
}



.platform-button.lazada {
  padding-top: 5%;
  padding-bottom: 5%;
  cursor: pointer;
  --c: #f1f1f1;
  box-shadow: 0 0 0 .1em inset var(--c);
  --_g: linear-gradient(var(--c) 0 0) no-repeat;
  background:
    var(--_g) calc(var(--_p, 0%) - 100%) 0%,
    var(--_g) calc(200% - var(--_p, 0%)) 0%,
    var(--_g) calc(var(--_p, 0%) - 100%) 100%,
    var(--_g) calc(200% - var(--_p, 0%)) 100%;
  background-size: 50.5% calc(var(--_p, 0%)/2 + .5%);
  outline-offset: .1em;
  transition: background-size .4s, background-position 0s .4s;
}

.platform-button.lazada:hover {
  --_p: 100%;
  transition: background-position .4s, background-size 0s;
  color: #181818;

}

.Lazadajsx-icon {
  margin-bottom: -10%;
  width: 2rem;
  height: 2rem;
}

.platform-button.lazada .Lazadajsx-icon path,
.platform-button.lazada .Lazadajsx-icon svg {
  fill: #ececec !important;
}

.platform-button.lazada:hover .Lazadajsx-icon path,
.platform-button.lazada:hover .Lazadajsx-icon svg {
  fill: #181818 !important;
}

.platform-button.lazada:active {
  box-shadow: 0 0 9e9q inset #0009;
  background-color: var(--c);
  color: #ececec;
}

.platform-button.lazada {
  background-color: #000083;
  color: #ececec;
  border-radius: 1rem;
  border: none;
}

.platform-button span {
  font-family: 'kanit';
  font-size: 1rem;
  border: none;
}

.product-info.scrolled .info-left h2,
.product-info.scrolled .info-left .price {
  margin: 5px 0;
  color: white;
}


.toggle-content{
  margin-right: 30%;
  text-align: left;
  margin-top: 10px;
}

.info-right {
  flex: 1;
  padding-left: 20px;
}

.product-info.scrolled .info-right {
  display: none;
}


.size-custom {
  text-align: center;
  font-size: larger;
  width: 30%;
  margin: 10px;
}

.custom-dropdown {
  position: relative;
  width: 35vh;
  cursor: pointer;
  margin-bottom: 10px;
  color: #272727 !important;
}

.dropdown-header {
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #fff;
}

.dropdown-list {
  position: absolute;
  width: 20rem;
  background-color: #fff;
  border: 1px solid #ccc;
  z-index: 10;
  overflow-y: auto;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

.dropdown-item {
  padding: 20px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}

.custom-dropdown .dropdown-list.open-up {
  top: auto;
  bottom: 100%;
  margin-bottom: 5px;
}

.product-card-Dcon {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 0;
  margin-right: 0;
  bottom: 100px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  box-sizing: border-box;
  left: 5%;
}

.product-card-D h3 {
  margin-bottom: 20%;
  padding-bottom: 35%;
}

.product-D {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  align-items: flex-start;
  min-width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}



.product-card-D {
  position: relative;
  overflow: hidden;
  margin: 20px auto;
  padding: 0 20px;
}

.suggested-product-card {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.product-D {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  /* Enable smooth scrolling on mobile */
}

.product-D::-webkit-scrollbar {
  display: none;
  /* Hide scrollbar for Webkit browsers (Chrome, Safari) */
}

/* Color Selection Box */
.color-selection {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 15px;

}

.color-item {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid #262626;
  transition: border 0.3s ease;
}

.color-item:hover {
  border: 2px solid #272727;
  /* Dark border on hover */
}

.color-item.selected {
  border: 2px solid #ff4e00;
  /* Selected color border */
}

/* Size Selection Box */
.size-selection {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 15px;
  padding-bottom: 2%;
}

.size-item {
  padding: 10px 20px;
  border: 2px solid #ccc;
  cursor: pointer;
  color: #272727;
  background-color: rgb(255, 255, 255);
  transition: background-color 0.3s ease, border-color 0.3s ease;
  font-size: 16px;
  border-radius: 4px;
}

.size-item:hover {
  background-color: #f1f1f1;
  border-color: #272727;
  /* Dark border on hover */
}

.size-item.selected {
  background-color: #2a2a2a;
  color: white;
  border-color: #5d5d5d;
  /* Selected size background */
}

.filter-option-selected button:hover {
  color: #666;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .scroll-button {
    top: 35%;
    font-size: 3.5rem;
  }
  .product-info.scrolled{
    visibility: hidden;
  }

  .product-info {
    flex-direction: column;
    align-items: center;

    margin-left: -1%;
    width: 100%;
  }

  .slideshow {
    padding-top: 20%;
  }

  .info-left {
    margin-bottom: 20px;
  }

  .info-right{
    width: 80%;
    margin-left: 20%;
  }

  .product-slide {
    width: 100%;
    height: 100%;
    /* Show 1 image at a time on smaller screens */
  }

  .images-wrapper {
    transition: transform 0.5s ease-in-out;
  }

  .custom-dropdown {
    width: 100%;
  }

  .product-info.scrolled {
    width: 95%;
  }

  .product-D {
    flex-direction: column;
    gap: 15px;
  }
}

@media (max-width: 480px) {

  .product-info.scrolled {
    padding: 5px;
    font-size: 0.9rem;
  }
}

.toggleContainer {
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
  max-height: 0;

} .show {
  max-height: 500px;
  /* Adjust according to the content */
}

.toggle-header {
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.plus-minus {
  --primary: #1E2235;
  --secondary: #FAFBFF;
  --duration: .5s;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  outline: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transform-style: preserve-3d;
  perspective: 240px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  border: 2px solid var(--primary);
  background-size: 300% 300%;
  transition: transform 0.3s;
  transform: scale(var(--scale, 1)) translateZ(0);
  -webkit-animation: var(--name, unchecked) var(--duration) ease forwards;
  animation: var(--name, unchecked) var(--duration) ease forwards;
}

.plus-minus:before, .plus-minus:after {
  content: "";
  position: absolute;
  width: 16px;
  height: var(--height, 0);
  left: 2.4px;
  top: var(--top, 2.5px);
  background: var(--background, var(--primary));
  -webkit-animation: var(--name-icon-b, var(--name-icon, unchecked-icon)) var(--duration) ease forwards;
  animation: var(--name-icon-b, var(--name-icon, unchecked-icon)) var(--duration) ease forwards;
}

.plus-minus:before {
  -webkit-clip-path: polygon(0 6px, 6px 6px, 6px 0, 10px 0, 10px 6px, 16px 6px, 16px 10px, 10px 10px, 10px 16px, 6px 16px, 6px 10px, 0 10px);
  clip-path: polygon(0 6px, 6px 6px, 6px 0, 10px 0, 10px 6px, 16px 6px, 16px 10px, 10px 10px, 10px 16px, 6px 16px, 6px 10px, 0 10px);
}

.plus-minus:after {
  --height: 2.5px;
  --top: 9px;
  --background: var(--secondary);
  --name-icon-b: var(--name-icon-a, checked-icon);
}

.plus-minus:active {
  --scale: .95;
}

.plus-minus:checked {
  --name: checked;
  --name-icon-b: checked-icon;
  --name-icon-a: unchecked-icon;
}

@keyframes checked-icon {
  from {
    transform: translateZ(12px);
  }

  to {
    transform: translateX(16px) rotateY(90deg) translateZ(12px);
  }
}

@keyframes unchecked-icon {
  from {
    transform: translateX(-16px) rotateY(-90deg) translateZ(12px);
  }

  to {
    transform: translateZ(12px);
  }
}

@keyframes checked {
  from {
    background-image: radial-gradient(ellipse at center, var(--primary) 0%, var(--primary) 25%, var(--secondary) 25.1%, var(--secondary) 100%);
    background-position: 100% 50%;
  }

  to {
    background-image: radial-gradient(ellipse at center, var(--primary) 0%, var(--primary) 25%, var(--secondary) 25.1%, var(--secondary) 100%);
    background-position: 50% 50%;
  }
}

@keyframes unchecked {
  from {
    background-image: radial-gradient(ellipse at center, var(--secondary) 0%, var(--secondary) 25%, var(--primary) 25.1%, var(--primary) 100%);
    background-position: 100% 50%;
  }

  to {
    background-image: radial-gradient(ellipse at center, var(--secondary) 0%, var(--secondary) 25%, var(--primary) 25.1%, var(--primary) 100%);
    background-position: 50% 50%;
  }
}

.size-table {
  width: 100%;
  border-collapse: collapse;
}

.size-table th,
.size-table td {
  padding: 8px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

.size-table th {
  background-color: #ffffff;
}

.size-table tr:hover {
  background-color: #f5f5f5;
}